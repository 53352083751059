import { observer } from "mobx-react"
import style from './index.module.css'
import { useAppStore } from "../../hooks/useAppStore"
import LessonHeader from "../../components/LessonHeader";
import CoursesList from "../../components/CoursesList";
import { useEffect, useMemo } from "react";
import { useBottonContent } from "../../hooks/useBottomContent";
import { useCurtainControl } from "../../hooks/useCurtainContol";
import CurtainLesson from "../../components/CurtainContents/CurtainLesson";
import { Statuses } from "../../components/MyCourseCard";
import { useNavigate, useParams } from "react-router-dom";

const LessonContent = () => {
    const {lesson, updateLesson} = useAppStore(); 
    const navigate = useNavigate();
    const {setProps} = useBottonContent()
    const params = useParams();
    const {courseId} = params;
    const {setProps: setCurtainProps} = useCurtainControl();

    const videoId = useMemo(() => {
        const urlArray = lesson?.url.split('/')
        if(urlArray) {
            return urlArray[urlArray?.length - 1].split('watch?v=')[1]
        }

        return '';
    }, [lesson?.url])

    useEffect(() => {
        if(lesson && courseId) {
            if(lesson.status === Statuses.NEW) {
                setCurtainProps({
                    isOpen: true,
                    closeBlock: true,
                    children: <CurtainLesson
                        description={lesson.description}
                        buttonProps={{
                            title: 'Начать',
                            callback: () => {
                                updateLesson({
                                    course_id: courseId,
                                    lesson_id: lesson.id,
                                    was_first_viewing: true,
                                    callback: () => {
                                        setCurtainProps({
                                            isOpen: false
                                        })
                                        setTimeout(() => {
                                            setCurtainProps({
                                                children: undefined
                                            })
                                        }, 300)
                                    }
                                })
                            }
                        }}
                    />
                })
                setProps({
                    isOpen: true,
                    buttonProps: {
                        title: 'Следующий урок',
                        backgroundColor: 'rgba(121, 93, 149, 1)',
                        titleColor: `rgba(164, 164, 165, 1)`,
                    },
                })
            }

            if(lesson.status === Statuses.FAKESTARTED) {
                setProps({
                    isOpen: true,
                    buttonProps: {
                        title: 'Следующий урок',
                        backgroundColor: 'rgba(121, 93, 149, 1)',
                        titleColor: `rgba(164, 164, 165, 1)`,
                    },
                })
            }

            if(lesson.status === Statuses.INPROGRESS) {
                if(lesson.nextLesson) {
                    if(lesson.nextLesson === lesson.id) {
                        setProps({
                            isOpen: true,
                            callback: () => {
                                updateLesson({
                                    course_id: courseId,
                                    lesson_id: lesson.id,
                                    status: 'done',
                                    callback: () => {navigate(`/mycourse/${courseId}`)}
                                })
                            },
                            buttonProps: {
                                title: 'Завершить урок',
                                backgroundColor: 'rgba(183, 136, 229, 1)',
                                titleColor: `rgba(255, 255, 255, 1)`,
                            },
                        })
                    } else {
                        setProps({
                            isOpen: true,
                            callback: () => {
                                updateLesson({
                                    course_id: courseId,
                                    lesson_id: lesson.id,
                                    status: 'done',
                                    callback: () => {navigate(`/lesson/${courseId}/${lesson.nextLesson}`)}
                                })
                            },
                            buttonProps: {
                                title: 'Следующий урок',
                                backgroundColor: 'rgba(183, 136, 229, 1)',
                                titleColor: `rgba(255, 255, 255, 1)`,
                            },
                        })
                    }
                }
            }

            if(lesson.status === Statuses.COMPLETED) {
                setProps({
                    isOpen: false,
                    buttonProps: {
                        title: 'Следующий урок',
                        backgroundColor: 'background: rgba(183, 136, 229, 1)',
                        titleColor: `rgba(255, 255, 255, 1)`,
                    },
                })
            }
        }

        return () => {
            setCurtainProps({
                isOpen: false,
                closeBlock: false,
            })
            setProps({
                isOpen: false,
            })
        }
    // eslint-disable-next-line
    }, [lesson?.status, courseId])

    useEffect(() => {
        return () => {
            setCurtainProps({
                isOpen: false,
                closeBlock: false,
            })
            setProps({
                isOpen: false,
            })
        }
    // eslint-disable-next-line
    }, [])

    const handleClick = useMemo(() => () => {
        if(lesson && courseId) {
            setCurtainProps({
                isOpen: true,
                children: <CurtainLesson
                    description={lesson.description}
                    setClose={() => {
                        setCurtainProps({
                            isOpen: false
                        })
                        setTimeout(() => {
                            setCurtainProps({
                                children: undefined
                            })
                        }, 300)
                    }}
                />
            })
        } 
    // eslint-disable-next-line
    }, [lesson, courseId])

    
    return (
        <>
            {
                lesson && (
                    <>
                        <LessonHeader
                            videoId={videoId}
                            onPlay={() => {
                                if(courseId && lesson) {
                                    updateLesson({
                                        course_id: courseId,
                                        lesson_id: lesson.id,
                                        status: 'in_progress',
                                    })
                                }
                            }}
                        />
                        <div className={style.wrapper}>
                            <div className={style.title}>{lesson.title}</div>
                            <div className={style.more}>
                                <span onClick={handleClick}>Подробнее</span>
                                <div className={style.arrow}></div>
                            </div>
                            <div className={style.need}>
                                <CoursesList
                                    title={'Потребуется для урока'}
                                    courses={lesson.recomendations}
                                    negativeMargin={16}
                                    height={235}
                                    paddingLeft={8}
                                    titlePadding={16}
                                    titleSize={16}
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </>
    )
}

export default observer(LessonContent)