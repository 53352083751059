import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useEffect } from 'react';
import cn from 'classnames'
import { observer } from 'mobx-react';

import style from './App.module.css'

import MainPage from './pages/MainPage';
import CategoryPage from './pages/CategoryPage';
import CoursePage from './pages/CoursePage';
import DetailCoursePage from './pages/DetailCoursePage';
import MyCoursePage from './pages/MyCoursePage';
// eslint-disable-next-line
import LessonPage from './pages/LessonPage';

import { getTg } from './utils/getTg';
import { useBottonContent } from './hooks/useBottomContent';
import BottomContent from './components/BottomContent';
import ProfilePage from './pages/ProfilePage';


const router = createBrowserRouter([
    {
        // it renders this element
        element: <MainPage />,
    
        // when the URL matches this segment
        path: "/",
    },
    {
        element: <CategoryPage />,
        path: '/category/:id',
    },
    {
        element: <CoursePage />,
        path: '/course/:id',
    },
    {
        element: <DetailCoursePage />,
        path: '/course/detail/:id',
    },
    {
        element: <MyCoursePage />,
        path: '/mycourse/:id',
    },
    {
        element: <LessonPage />,
        path: '/lesson/:courseId/:lessondId',
    },
    {
        element: <ProfilePage />,
        path: '/profile/',
    }
  ],
);


function App() {

    const {isOpen} = useBottonContent();

    useEffect(() => {
        const {tg} = getTg();
        tg.disableVerticalSwipes()
        tg.setHeaderColor('#1c1c1d')
        sessionStorage.setItem('initData', tg.initData || process.env.REACT_APP_INIT_DATA)
        console.log('tg: 1', tg.initDataUnsafe)
        console.log('tg: 2', tg.initData)
    }, []);

    
    return (
        <main id="main" className={cn(style.dark, style.main, {[style.bottomPadding]: isOpen})}>
            <RouterProvider router={router} />
            <BottomContent />
        </main>
    );
}

export default observer(App);
