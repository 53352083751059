import { useEffect, useMemo } from "react"

const useBlockGlobalScroll = () => {
    const setGlobalScroll = useMemo(() => (scrollBehavior: string) => {
        console.log('setGlobalScroll')
        document.documentElement.style.overflowY = scrollBehavior;
        document.documentElement.style.overflowX = 'hidden';
    }, [])

    useEffect(() => {
        console.log('setGlobalScroll useEffect')
        document.documentElement.style.overflowY = 'scroll';
        document.documentElement.style.overflowX = 'hidden';

        return () => {
            console.log('setGlobalScroll useEffect return')
            document.documentElement.style.overflowY = 'scroll';
            document.documentElement.style.overflowX = 'hidden';
        }
    }, [])

    return {setGlobalScroll}
}

export default useBlockGlobalScroll;