import {action, makeAutoObservable} from 'mobx'
import $api from '../api';
// eslint-disable-next-line
import { CategoriesResponseType, CategoryResponseType, MyCoursesResponseType } from '../api/types';
import MyCoursesNormalize, { MyCoursesType } from '../normalizers/myCoursesNormalize';
// eslint-disable-next-line
import ReturnedResponse from '../helpers/returnedResponse';
import DetailCourseNormalize, { CourseType } from '../normalizers/detailCourseNormalize';
import MyCourseNormalize, { MyCourseType } from '../normalizers/myCourseNormalize';
import LessonNormalize, { LessonType } from '../normalizers/lessonNormalize';

export interface MyCoursesInterface {
    inprocess?: Array<MyCoursesType>,
    completed?: Array<MyCoursesType>,
}

export type UpdateLessonData = {
    course_id: string,
    lesson_id: string,
    was_first_viewing?: boolean,
    status?: 'todo' | 'in_progress' | 'done',
    callback?: () => void,
}

interface IAppStore {
    availableCategories: Array<CategoriesResponseType>,
    myCourses: MyCoursesInterface,
    category?: CategoryResponseType,
    course?: CourseType,
    myCourse?: MyCourseType,
    lesson?: LessonType,
}


export class AppStore implements IAppStore {
    currentScroll: number = 0;
    availableCategories: Array<CategoriesResponseType> = [];
    myCourses: MyCoursesInterface = {};
    category: CategoryResponseType | undefined;
    course: CourseType | undefined;
    myCourse: MyCourseType | undefined;
    lesson: LessonType | undefined;

    @action getAvailableCategories = () => {
        this.availableCategories = [];
        $api.get<CategoriesResponseType>('/api/v1/catalog/categories')
            .then((res) => {
                this.setAvailableCategories(ReturnedResponse(res));
            })
            .catch((err) => {
                this.setAvailableCategories(ReturnedResponse(err));
            })
    }

    @action setAvailableCategories = (data: Array<CategoriesResponseType>) => {
        this.availableCategories = data;
    }

    @action getMyCourses = async () => {
        this.myCourses = {
            inprocess: [],
            completed: [],
        };
        $api.get<MyCoursesResponseType>('/api/v1/users/me/courses/all')
            .then((res) => {
                this.setMyCourses(MyCoursesNormalize(ReturnedResponse(res)));
            })
            .catch((err) => {
                this.setMyCourses(MyCoursesNormalize(ReturnedResponse(err)));
            })
    }

    @action setMyCourses = (data: MyCoursesInterface) => {
        this.myCourses = data;
    }

    @action getCategory = (id: string) => {
        this.category = undefined;
        $api.get<CategoryResponseType>(`/api/v1/catalog/category/${id}`)
            .then((res) => {
                this.setCategory(ReturnedResponse(res));
            })
            .catch((err) => {
                this.setCategory(ReturnedResponse(err));
            })
    }

    @action setCategory = (data: CategoryResponseType) => {
        this.category = data;
    }

    @action getCourse = async (id: string) => {
        this.course = undefined;
        $api.get<CategoryResponseType>(`/api/v1/catalog/course/${id}`)
            .then((res) => {
                this.setCourse(DetailCourseNormalize(ReturnedResponse(res)));
            })
            .catch((err) => {
                this.setCourse(DetailCourseNormalize(ReturnedResponse(err)));
            })
    }

    @action setCourse = (data: CourseType) => {
        this.course = data;
    }

    @action getMyCourse = (id: string) => {
        this.myCourse = undefined;
        $api.get(`/api/v1/users/me/courses/${id}`)
            .then((res) => {
                this.setMyCourse(MyCourseNormalize(ReturnedResponse(res)))
            })
            .catch((err) => {
                this.setMyCourse(MyCourseNormalize(ReturnedResponse(err)))
            })
    }

    @action setMyCourse = (data: MyCourseType | undefined) => {
        this.myCourse = data;
    }

    @action getLesson = (courseId: string, lessondId: string) => {
        this.lesson = undefined;
        $api.get(`/api/v1/users/me/courses/${courseId}/${lessondId}`)
            .then((res) => {
                this.setLesson(LessonNormalize(ReturnedResponse(res)))
            })
            .catch((err) => {
                this.setLesson(LessonNormalize(ReturnedResponse(err)))
            })
    }

    @action setLesson = (data: LessonType) => {
        this.lesson = data;
    }

    @action buyCourse = (id: string) => {
        $api.post('/api/v1/payment/course/buy', {
            course_id: id
        })
            .then((res) => {
                if(this.course) {
                    this.course = {...this.course, isBuyed: true};
                }
            })
            .catch((err) => {
                console.log('err:', err);
            })
    }

    @action updateLesson = (data: UpdateLessonData) => {
        const innerData: any = {};
        if(data.was_first_viewing !== undefined) {
            innerData['was_first_viewing'] = data.was_first_viewing;
        }

        if(data.status !== undefined) {
            innerData['status'] = data.status;
        }

        $api.post(`/api/v1/users/me/courses/${data.course_id}/${data.lesson_id}/update`, innerData)
            .then((res) => {
                this.setLesson(LessonNormalize(ReturnedResponse(res)))
                if(data.callback) {
                    data.callback();
                }
            })
            .catch((_) => {
                if(this.lesson) {
                    this.setLesson(this.lesson)
                }
            })

    }

    @action completeCourse = (id: string) => {
        $api.get(`/api/v1/users/me/courses/${id}/complete`)
            .then((res) => {
                this.setMyCourse(MyCourseNormalize(ReturnedResponse(res)))
            })
            .catch((error) => {
                if(this.myCourse) {
                    this.setMyCourse(this.myCourse)
                }
            })

    }

    @action setCurrentScroll = (scroll: number) => {
        this.currentScroll = scroll;
    }

    constructor() {
        makeAutoObservable(this);
    }
}