import { useEffect, useRef, useState } from "react"
import style from './index.module.css'
import { observer } from "mobx-react"
import isOverflowed from "../../helpers/isOverflowed";

interface DropDownTextProps {
    description: string,
}

const DropDownText = ({description}: DropDownTextProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isShowBuutton, setIsShowButton] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(ref.current) {
            const overflowed = isOverflowed(ref.current)
            if(overflowed) {
                setIsShowButton(true)
            } else {
                setIsShowButton(false)
            }
        }
    }, [description])

    return (
        <div className={style.wrapper}>
            <div 
                ref={ref}
                className={style.text}
                style={{
                    lineClamp: `${isOpen ? 200 : 3}`,
                    WebkitLineClamp: `${isOpen ? 200 : 3}`,
                }}
            >{description}</div>
            {
                isShowBuutton && (
                    <div
                        onClick={() => {
                            setIsOpen((prev) => !prev)
                        }}
                        className={style.button}>
                        {
                            isOpen ? "Свернуть" : "Показать полностью"
                        }
                        <div
                            className={style.arrow}
                            style={{
                                transform: `rotate(${isOpen ? 180 : 0}deg)`
                            }}
                        ></div>
                    </div>
                )
            }
        </div>
    )
}

export default observer(DropDownText)