import { observer } from "mobx-react"
import { useState } from "react"
import { useAppStore } from "../../hooks/useAppStore"
import style from './index.module.css'
import MyCourseCard from "../MyCourseCard"
import CompletedCurtain from "../CompletedCurtain"
import { MyCoursesType } from "../../normalizers/myCoursesNormalize"
import MainPageMyCoursesLoader from "../PageLoaders/MainPageMyCoursesLoader"


const MyCoursesList = () => {
    const {myCourses} = useAppStore()
    const [isOpen, setIsOpen] = useState(false);

    if(myCourses.inprocess === undefined && myCourses.completed === undefined) {
        return <MainPageMyCoursesLoader />
    }

    return (
        <div className={style.wrapper}>
            {
                myCourses.inprocess && myCourses.inprocess.map((course: MyCoursesType) => (
                    <MyCourseCard
                        {...course}
                        key={course.id}
                    />
                ))
            }
            {
                myCourses.completed && myCourses.completed.length > 0 && (
                    <CompletedCurtain isOpen={isOpen} setIsOpen={setIsOpen} items={myCourses.completed}/>
                )
            }
        </div>
    )
}

export default observer(MyCoursesList)